
















import {
  activeCallStore,
} from '@/store'

import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'

/**
 * Has to be syncronous for now because showCallLg watcher relies on its ref
 * to be available onNextTick
 */
import TheCallLg from './TheCallLg/index.vue'
import { ActiveCall, CallDisplayType } from '@/store/modules/activeCall/models'
import { callsLogger } from '@/loggers'

const DEFAULT_DISPLAY_AS = {
  sm: false,
  md: false,
  lg: false,
}

@Component({
  components: {
    TheCallMd: () => import('./TheCallMd/index.vue'),
    TheCallLg,
    TheCallSm: () => import('./TheCallSm.vue'),
  },
})
export default class Calls extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  @Ref() readonly callLg!: TheCallLg | undefined

  private displayAs = DEFAULT_DISPLAY_AS

  private get uiDisplayType (): CallDisplayType {
    return activeCallStore.state.uiDisplayType
  }

  private get uiDisplayTypePrev (): CallDisplayType {
    return activeCallStore.state.uiDisplayTypePrev
  }

  private get isFullscreenActive (): boolean {
    return this.$q.fullscreen.isActive
  }

  @Watch('uiDisplayType', { immediate: true })
  async onUiDisplayTypeChanged (
    newV: Calls['uiDisplayType'],
    oldV: Calls['uiDisplayType'],
  ): Promise<void> {
    callsLogger.debug('Call display type changed from', oldV, 'to', newV)

    if (!newV) {
      callsLogger.error('UI display type changed to', newV, 'while call was still active.')
      return
    }

    if (newV === oldV) {
      callsLogger.warn('Detected change to the same UI display type. Probably fine.')
      return
    }

    if (oldV === 'fullscreen') {
      await this.$q.fullscreen.exit()
    }

    if (newV === 'card') {
      this.selectDisplayOption('card')
      return
    }

    if (newV === 'bar') {
      this.selectDisplayOption('bar')
      return
    }

    if (newV === 'fullscreen') {
      if (!this.$q.fullscreen.isCapable) {
        callsLogger.error('Tried changing to fullscreen when user agent is not capable of it.')
        return
      }
      this.selectDisplayOption('fullscreen')
      await this.$nextTick()
      const el = this.callLg?.$el
      if (!el) {
        callsLogger.error('Tried entering fullscreen before LG call component was mounted')
        activeCallStore.actions.setUiDisplayType(this.uiDisplayTypePrev)
        return
      }
      await this.$q.fullscreen.request(el)
      return
    }

    // shouldn't ever get here
    callsLogger.error('Tried changing to unknown display type:', newV)
  }

  /**
   * This handler is for cases when user is exiting fullscreen via system
   * or browser command (e.g. Esc button press).
   * User selected display type will remain 'fullscreen' since user didn't
   * select to change it, but browser fullscreen state will change.
   * Detect this and change user-selected display type to a previous one.
   */
  @Watch('isFullscreenActive')
  async onIsFullscreenActiveChanged (
    newV: Calls['isFullscreenActive'],
    oldV: Calls['isFullscreenActive'],
  ): Promise<void> {
    if (oldV && !newV && this.uiDisplayType === 'fullscreen') {
      activeCallStore.actions.setUiDisplayType(this.uiDisplayTypePrev)
    }
  }

  private selectDisplayOption (o: NonNullable<CallDisplayType>): void {
    callsLogger.log('Changing call display to', o)

    const t = { ...DEFAULT_DISPLAY_AS }
    if (o === 'card') t.sm = true
    else if (o === 'bar') t.md = true
    else if (o === 'fullscreen') t.lg = true
    this.displayAs = t
  }
}
