





































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CallEntity } from '@/store/modules/activeCall/models'
import CallMemberCard from '@/components/Calls/ActiveCall/TheCallMd/CallDetails/CallDetailsContent/CallMemberCard/index.vue'
import ScrollArea from '@/components/Calls/ScrollArea.vue'
import { activeCallStore } from '@/store'
import { callsLogger } from '@/loggers'

@Component({
  components: {
    CallMemberCard,
    ScrollArea,
  },
})
export default class MembersList extends Vue {
  @Prop({
    type: Array,
    required: true,
    validator: (a: unknown) => (
      a instanceof Array &&
      a.every(e => e instanceof CallEntity)
    ),
  }) readonly entities!: CallEntity[]

  private pinMember (jid: string): void {
    window.goal('callControls', { callControls: 'Даблклик — Развернуть' })
    callsLogger.log('Selecting presenter', jid)
    activeCallStore.actions.pinMember(jid)
  }
}
