









import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActiveCall, CallEntity } from '@/store/modules/activeCall/models'
import { activeCallStore } from '@/store'
import LayoutGrid from './LayoutGrid.vue'
import LayoutPresenter from './LayoutPresenter/index.vue'

@Component
export default class TheCallLgGroup extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  get showGrid (): boolean {
    return activeCallStore.state.uiLgLayoutType === 'GRID'
  }

  get layout (): Vue.Component {
    const t = activeCallStore.state.uiLgLayoutType
    if (t === 'GRID') return LayoutGrid
    if (t === 'PRESENTER') return LayoutPresenter

    /**
     * Never really gets here
     */
    throw new Error(`Unexpected layout type ${t}`)
  }

  /**
   * Always display at least one user (self) in group/task call,
   * even if call hasn't connected yet.
   */
  get callEntities (): CallEntity[] {
    const result = activeCallStore.getters.connectedEntities

    return result
    // return [...result, ...result, ...result, ...result, ...result, ...result]
    // return [...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result, ...result]
  }

  private unPinMember () {
    const type = activeCallStore.state.uiLgLayoutType
    if (type !== 'PRESENTER') return
    window.goal('callControls', { callControls: 'Даблклик — Свернуть' })
    const lastSize = activeCallStore.state.uiDisplayTypePrev
    activeCallStore.actions.setUiDisplayType(lastSize)
  }
}
