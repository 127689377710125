







































import AddMembers from '@/components/Calls/ActiveCall/AddMembers/index.vue'
import CallControlsStrip from '@/components/Calls/ActiveCall/CallControlsStrip/index.vue'
import { activeCallStore } from '@/store'
import { ActiveCall } from '@/store/modules/activeCall/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CallDetailsHeader from '../TheCallMd/CallDetails/CallDetailsHeader/index.vue'
import Wrapper from '../Wrapper.vue'
import TheCallLgDirect from './TheCallLgDirect.vue'
import TheCallLgGroup from './TheCallLgGroup/index.vue'

const MARGIN_X = 16 * 2 // q-ma-md

@Component({
  components: {
    CallControlsStrip,
    TheCallLgDirect,
    TheCallLgGroup,
    AddMembers,
    Wrapper,
    CallDetailsHeader,
  },
})
export default class TheCallLg extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  currentWidth = 0

  wasUnmutedBySpaceBtn = false

  get isCallDirect (): boolean {
    return activeCallStore.getters.isActiveCallDirect
  }

  get showAddMembers (): boolean {
    return activeCallStore.state.uiLgRightBarDisplayType === 'ADD_MEMBERS'
  }

  private onResize (size: { height: number, width: number }) {
    this.currentWidth = size.width - MARGIN_X
  }

  mounted () {
    window.addEventListener('keydown', this.keydownHandler, true)
    window.addEventListener('keyup', this.keyupHandler, true)
  }

  beforeDestroy () {
    window.removeEventListener('keydown', this.keydownHandler, true)
    window.removeEventListener('keyup', this.keyupHandler, true)
  }

  private keydownHandler (event: KeyboardEvent) {
    if (event.code !== 'Space') return
    event.preventDefault()
    event.stopPropagation()

    if (event.repeat) return
    if (!this.call.isAudioMuted) return

    this.wasUnmutedBySpaceBtn = true
    activeCallStore.actions.toggleAudio()
  }

  private keyupHandler (event: KeyboardEvent) {
    if (event.code !== 'Space') return
    event.preventDefault()
    event.stopPropagation()

    if (this.call.isAudioMuted) return
    if (!this.wasUnmutedBySpaceBtn) return

    activeCallStore.actions.toggleAudio()
    this.wasUnmutedBySpaceBtn = false
    window.goal('callControls', { callControls: '[LG] Пробел анмьют' })
  }
}
