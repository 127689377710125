












import { QBtn } from 'quasar'
import { Component } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class AddMembersBtn extends QBtn {
  // @Prop({
  //   type: String,
  //   required: true
  // }) readonly label!: string

  // @Prop({
  //   type: String,
  //   required: true
  // }) readonly color!: string

  // @Prop({
  //   type: String,
  //   required: true
  // }) readonly textColor!: string

  // @Prop({
  //   type: Boolean,
  //   default: false
  // }) readonly disable!: boolean
}
