






import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ScrollArea extends Vue {
  // scroll styling for call member cards in group/task call
  private readonly thumbStyle: Partial<CSSStyleDeclaration> = {
    right: '4px',
    borderRadius: '4px',
    backgroundColor: 'var(--td-gray-16)',
    width: '4px',
    opacity: '1',
  }
}
