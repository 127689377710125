














import { activeCallStore } from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { CallDisplayType } from '@/store/modules/activeCall/models'
import {
  IconFullScreenM,
  IconMiddleScreenM,
  IconMiniScreenM,
} from '@tada/icons'
import { TdButtonCall } from 'td-ui'

type MenuOption = {
  title: string;
  action: () => unknown;
  active: boolean;
  icon: Vue.Component;
}

@Component({
  components: {
    TdButtonCall,
    IconFullScreenM,
    IconMiddleScreenM,
    IconMiniScreenM,
  },
})
export default class CallDisplayTypeBtns extends Vue {
  // icons signatures
  iconCheck!: string

  get fullOption (): MenuOption {
    return {
      title: this.$t('calls.screenSizes.full').toString(),
      action: () => this.switchTo('fullscreen'),
      active: activeCallStore.state.uiDisplayType === 'fullscreen',
      icon: IconFullScreenM,
    }
  }

  get middleOption (): MenuOption {
    return {
      title: this.$t('calls.screenSizes.middle').toString(),
      action: () => this.switchTo('bar'),
      active: activeCallStore.state.uiDisplayType === 'bar',
      icon: IconMiddleScreenM,
    }
  }

  get miniOption (): MenuOption {
    return {
      title: this.$t('calls.screenSizes.mini').toString(),
      action: () => this.switchTo('card'),
      active: activeCallStore.state.uiDisplayType === 'card',
      icon: IconMiniScreenM,
    }
  }

  get options (): MenuOption[] {
    const o = [
      this.middleOption,
      this.miniOption,
    ]

    if (this.$q.fullscreen.isCapable) o.unshift(this.fullOption)

    return o
  }

  private getMetrikaString (type: CallDisplayType): string {
    if (type === 'bar') return 'Включить средний размер экрана'
    if (type === 'card') return 'Включить маленький размер экрана'
    if (type === 'fullscreen') return 'Включить большой размер экрана'
    throw new Error(`Unknown call display type: ${type}`)
  }

  private switchTo (to: CallDisplayType): void {
    const metrika = this.getMetrikaString(to)
    window.goal('callControls', { callControls: metrika })
    activeCallStore.actions.setUiDisplayType(to)
  }
}
