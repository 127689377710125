








import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActiveCall, CallEntity } from '@/store/modules/activeCall/models'
import { activeCallStore } from '@/store'
import DirectCallMemberCard from '@/components/Calls/ActiveCall/TheCallMd/CallDetails/CallDetailsContent/DirectCallMemberCard.vue'

@Component({
  components: {
    DirectCallMemberCard,
  },
})
export default class TheCallLgDirect extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  get entity (): CallEntity {
    const entityJid = this.call.jid
    const o = this.call.connectedMembers.find(m => m.jid === entityJid)
    if (!o) {
      return activeCallStore.getters.createCallEntity(entityJid)
    }
    return activeCallStore.getters.createCallEntity(o)
  }

  private unPinMember () {
    window.goal('callControls', { callControls: 'Даблклик — Свернуть' })
    const lastSize = activeCallStore.state.uiDisplayTypePrev
    activeCallStore.actions.setUiDisplayType(lastSize)
  }
}
