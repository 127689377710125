















































import { biTelephone } from '@quasar/extras/bootstrap-icons'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ContactItem } from '@/components/Calls/ActiveCall/AddMembers/AddMembersContent/models'
import { matCheckCircle } from '@quasar/extras/material-icons'
import CallMemberAvatar from '@/components/Calls/CallMemberAvatar.vue'

type IconProps = {
  name: string;
  color: string;
  class?: string | string[];
}

@Component({
  name: 'ListItemsContact',
  components: {
    CallMemberAvatar,
  },
})
export default class ListItemsContact extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly item!: ContactItem

  // icon signatures
  iconSelected!: string
  iconConnected!: string

  get showIcon (): boolean {
    return this.item.isConnected || this.item.isSelected
  }

  get iconProps (): IconProps {
    if (this.item.isConnected) {
      return {
        name: this.iconConnected,
        color: 'td-gray-16',
        class: 'flip-horizontal',
      }
    }
    if (this.item.isSelected) {
      return {
        name: this.iconSelected,
        color: 'td-blue-3',
      }
    }
    // should never really get here
    throw new Error(
      'Tried getting icon props when item was neither connected or selected',
    )
  }

  created () {
    this.iconSelected = matCheckCircle
    this.iconConnected = biTelephone
  }
}
