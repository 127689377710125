


































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { ContactItem, ListItem, SectionItem } from './models'
import { activeCallStore, groupsStore, sectionsStore, tasksStore, meetingsStore } from '@/store'
// import { NewActiveCall } from '@/store/modules/calls/models'
import { getDefaultFuseOptions, sortEntitiesByName } from '@/utils'
import { multiSearch } from '@/utils/Common'
import { ActiveCall } from '@/store/modules/activeCall/models'
import ScrollableList from '@/components/Calls/ScrollableList.vue'
import * as Sentry from '@sentry/browser'
import { Contact, Section } from '@tada-team/tdproto-ts'
import Fuse from 'fuse.js'
// import { Component, Prop, Vue } from 'vue-property-decorator'
import ListItemsContact from './ListItems/ListItemsContact.vue'
import ListItemsSection from './ListItems/ListItemsSection.vue'
// import { ContactItem, ListItem, SectionItem } from './models'

@Component({
  name: 'AddMembersContent',
  components: {
    AddMembersLoading: () => import('./AddMembersLoading.vue'),
    AddMembersNoResults: () => import('./AddMembersNoResults.vue'),
    ListItemsContact,
    ListItemsSection,
    ScrollableList,
  },
})
export default class AddMembersContent extends Vue {
  /**
   * Array of JIDs of selected members
   */
  @Prop({
    type: Array,
    required: true,
  }) readonly value!: string[]

  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  /**
   * String to fuzzy-filter contacts' names against
   */
  @Prop({
    type: String,
    default: '',
  }) readonly searchQuery!: string

  /**
   * Number of contacts left available to select
   */
  @Prop({
    type: Number,
    required: true,
  }) readonly spacesAvailable!: number

  private readonly fuseOptions = getDefaultFuseOptions(['displayName'])

  private isLoadingContacts = false

  /**
   * Sections JIDs which user decided to collapse.
   */
  private collapsedSections: string[] = []

  get contacts (): Contact[] {
    let contacts: Contact[]

    const t = activeCallStore.getters.activeCallChatType
    if (t === 'task') {
      contacts = tasksStore.getters.members(this.call.jid)
    } else if (t === 'group') {
      // prevent returning falsy val while contacts are loading
      if (this.isLoadingContacts) return []
      contacts = groupsStore.getters.members(this.call.jid)
    } else if (t === 'meeting') {
      const meeting = this.meeting
      contacts = !meeting ? [] : meeting.meetingMembers?.map(m => m.contact) ?? []
    } else {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Error)
        scope.setTag('calls', 'addMembers')
        scope.setContext('jid', { jid: this.call.jid })
        Sentry.captureException('Tried adding members to possibly direct call.')
      })
      return []
    }
    return contacts.filter(c => c.canCall)
  }

  get meeting () {
    return meetingsStore.state.loadedMeetings[this.call.jid]
  }

  get filteredContacts (): TADA.Contact[] {
    const query = this.searchQuery.trim()
    return query.length < 2
      ? this.contacts
      : multiSearch((s: string) => this.fuse.search(s), query, 'jid')
  }

  get sortedContacts (): TADA.Contact[] {
    return sortEntitiesByName(this.filteredContacts)
  }

  /**
   * All contact sections sorted + a 'no-section' section object in the end
   */
  get contactSections (): Section[] {
    /**
     * Have to use spread here to prevent silently mutation store.
     */
    const sections = [...sectionsStore.getters.contactSectionsSorted()]

    /**
     * Server does not return 'no-section' object, so append it ourselves.
     * This is fucked up, really
     */
    const noSection = new Section(
      new Date().getTime(),
      this.$t('modals.AddContact.noSection').toString(),
      Math.max(...sectionsStore.getters.contactSectionsSorted().map(s => s.sortOrdering)) + 10,
      'no-section',
      '',
      false,
    )
    sections.push(noSection)

    return sections
  }

  get contactsBySectionUid (): Record<string, TADA.Contact[]> {
    const record: Record<string, TADA.Contact[]> = {}
    this.sortedContacts.forEach(c => {
      /**
       * If contact has no sections - give it a 'no-section' section
       */
      c.sections.length === 0 && c.sections.push('no-section')

      /**
       * Add contact to each section where it belongs.
       * If record key does not exist - create it.
       */
      c.sections.forEach(s => {
        record[s] ? record[s].push(c) : record[s] = [c]
      })
    })

    return record
  }

  get listItems (): ReadonlyArray<ListItem> {
    const items: ListItem[] = []
    this.contactSections.forEach(s => {
      const contactsInSection = this.contactsBySectionUid[s.uid]
      /**
       * If there are no contacts to display in the list for this section -
       * do not show this section alltogether.
       *
       * Checking for undef (falsy) here is required because TS can not detect
       * if an object has a certain key before runtime.
       */
      if (!contactsInSection || contactsInSection.length === 0) return

      /**
       * First - create and add SectionItem to the list.
       * It will be displayed on top of contacts of same section.
       */
      const sectionItem: SectionItem = {
        name: s.name,
        uid: s.uid,
        type: 'section',
        key: s.uid,
      }
      items.push(sectionItem)

      /**
       * Do not add contacts to the list if user had collapsed this section.
       */
      if (this.collapsedSections.includes(s.uid)) return

      const contactItem: ContactItem[] = contactsInSection.map(c => {
        const isConnected = this.connectedJids.includes(c.jid)
        const isSelected = this.value.includes(c.jid)

        /**
         * Contact may still be selected when the limit is reached if:
         * - it is already selected (allow to deselect)
         * - it is already connected (will do nothing, but
         *   doesn't scare users with "cursor: not-allowed")
         */
        const isDisabled = !isConnected && !isSelected && this.limitReached

        return {
          jid: c.jid,
          name: c.displayName,
          icons: c.icons,
          role: c.role,
          isConnected,
          isSelected,
          isDisabled,
          type: 'contact',
          /**
           * Form a key this way to guarantee it is unique.
           * One contact may have several sections and
           * thus appear on the list several times.
           */
          key: c.jid + s.uid,
        }
      })

      items.push(...contactItem)
    })

    return Object.freeze(items)
  }

  get connectedJids (): string[] {
    return this.call.connectedMembers.map(m => m.jid)
  }

  get numInviting (): number {
    return this.value.length
  }

  get limitReached (): boolean {
    return this.numInviting >= this.spacesAvailable
  }

  get fuse (): Fuse<Contact> {
    return new Fuse(this.contacts, this.fuseOptions)
  }

  async created () {
    const t = activeCallStore.getters.activeCallChatType
    if (t === 'group') await this.loadMembers()
    if (t === 'meeting') {
      if (!this.meeting) {
        await meetingsStore.actions.loadMeeting(this.call.jid)
      }
    }
  }

  async loadMembers () {
    this.isLoadingContacts = true
    await groupsStore.actions.loadMembers(this.call.jid)
    this.isLoadingContacts = false
  }

  search (query: string): TADA.Contact[] {
    return multiSearch((text: string) => this.fuse.search(text), query, 'jid')
  }

  @Emit('input')
  onMemberClick (jid: string): string {
    return jid
  }

  onSectionClick (uid: string): void {
    const i = this.collapsedSections.indexOf(uid)
    i === -1
      ? this.collapsedSections.push(uid)
      : this.collapsedSections.splice(i, 1)
  }
}
