























import { Component, Prop, Vue } from 'vue-property-decorator'
import { biChevronDown, biChevronRight } from '@quasar/extras/bootstrap-icons'

@Component({ name: 'ListItemsSection' })
export default class ListItemsSection extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) readonly value!: boolean

  @Prop({
    type: String,
    required: true,
  }) readonly name!: string

  // icon signatures
  iconCollapse!: string
  iconExpand!: string

  get icon (): string {
    return this.value ? this.iconCollapse : this.iconExpand
  }

  created () {
    this.iconCollapse = biChevronDown
    this.iconExpand = biChevronRight
  }
}
