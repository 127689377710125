






















import CallMemberCard from '@/components/Calls/ActiveCall/TheCallMd/CallDetails/CallDetailsContent/CallMemberCard/index.vue'
import ScrollArea from '@/components/Calls/ScrollArea.vue'
import { callsLogger } from '@/loggers'
import { activeCallStore } from '@/store'
import { CallEntity } from '@/store/modules/activeCall/models'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CallMemberCard,
    ScrollArea,
  },
})
export default class LayoutGrid extends Vue {
  @Prop({
    type: Array,
    required: true,
    validator: (a: unknown) => (
      a instanceof Array &&
      a.every(e => e instanceof CallEntity)
    ),
  }) readonly entities!: CallEntity[]

  get classList (): string | string[] | Record<string, boolean> {
    const l = this.entities.length

    if (l <= 2) return 'col-6'
    if (l <= 6) return 'col-xs-4 col-sm-4 col-md-4 col-lg-3 col-xl-2'
    return 'col-xs-6 col-sm-4 col-md-3 col-lg-2 col-xl-2'
  }

  private pinMember (jid: string): void {
    window.goal('callControls', { callControls: 'Даблклик — Развернуть' })
    callsLogger.log('Selecting presenter', jid)
    activeCallStore.actions.setUiLgLayoutType('PRESENTER')
    activeCallStore.actions.pinMember(jid)
  }
}
