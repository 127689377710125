





















import { callsLogger } from '@/loggers'
import { ActiveCall, CallEntity } from '@/store/modules/activeCall/models'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import CallMemberCard from './CallMemberCard/index.vue'

@Component({
  components: {
    CallMemberCard,
  },
})
export default class DirectCallMemberCard extends Vue {
  @Prop({
    type: CallEntity,
    required: true,
  }) readonly entity!: CallEntity

  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  /**
   * Ref to element below contains stream video and shown conditionally.
   */
  @Ref() readonly video!: HTMLVideoElement

  get showVideo (): boolean {
    return (
      !this.call.isVideoMuted &&
      !!this.stream &&
      this.stream.getVideoTracks().length > 0
    )
  }

  get mirrored (): boolean {
    return !this.call.isScreensharing
  }

  get stream (): MediaStream | null {
    return this.call.localStreams.video
  }

  @Watch('stream', { immediate: true })
  onSelfVideoChanged (s: MediaStream) {
    callsLogger.warn('on SELF stream changed', s)
    if (!s) return

    this.$nextTick(() => {
      this.video.srcObject = s
    })
  }
}
