




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActiveCall, CallEntity } from '@/store/modules/activeCall/models'
import CallMemberCard from '@/components/Calls/ActiveCall/TheCallMd/CallDetails/CallDetailsContent/CallMemberCard/index.vue'
import store, { activeCallStore } from '@/store'
import { callsLogger } from '@/loggers'
import MembersList from './MembersList.vue'

@Component({
  components: {
    CallMemberCard,
    MembersList,
  },
})
export default class LayoutPresenter extends Vue {
  @Prop({
    type: Array,
    required: true,
    validator: (a: unknown) => (
      a instanceof Array &&
      a.every(e => e instanceof CallEntity)
    ),
  }) readonly entities!: CallEntity[]

  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  private get presenter (): CallEntity {
    const active = activeCallStore.getters.activePresenter
    if (active) return active

    const lastTalkingConnectedJid = this.call.talkingMembers.find(jid => {
      return this.entities.some(e => e.jid === jid)
    })
    const lastTalking = this.entities.find(e => {
      return e.jid === lastTalkingConnectedJid
    })
    if (lastTalking) return lastTalking

    const userId = store.getters.getUserId
    const self = this.entities.find(e => e.jid === userId)
    if (self) return self

    // should not ever get here
    callsLogger.warn('Could not find appropriate presenter entity.')
    return this.entities[0]
  }

  private get list (): CallEntity[] {
    return this.entities.filter(e => e.jid !== this.presenter.jid)
  }
}
