























import { Component, Prop, Vue } from 'vue-property-decorator'
import ScrollArea from './ScrollArea.vue'

@Component({
  components: {
    ScrollArea,
  },
})
export default class ScrollableList extends Vue {
  @Prop({
    type: Array,
    required: true,
  }) readonly items!: ReadonlyArray<unknown>

  @Prop({
    type: String,
    required: true,
  }) readonly wrapperId!: string

  /**
   * Hand-picked hard-coded item size for virtual scroll.
   * Based on the size of ListItemsContact.
   * It is slightly bigger than ListItemsSection, but better this way.
   */
  private readonly virtualScrollItemSize = 52
}
