




















































import { activeCallStore, teamsStore } from '@/store'
import { ActiveCall, CallEntity } from '@/store/modules/activeCall/models'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    CallMemberAvatar: () => import('@/components/Calls/CallMemberAvatar.vue'),
    IconMicOffS: () => import('@tada/icons/dist/IconMicOffS.vue'),
    SpeakingIcon: () => import('@/components/Calls/SpeakingIcon.vue'),
  },
})
export default class CallMemberCard extends Vue {
  @Prop({ type: CallEntity, required: true }) readonly entity!: CallEntity
  @Prop({ type: String, default: 's' }) readonly size!: 's' | 'm' | 'l'
  @Prop({ type: Boolean }) readonly tansparentBg?: boolean
  @Prop({ type: Boolean }) readonly showName?: boolean
  @Prop({ type: Boolean }) readonly fitVideo?: boolean

  /**
   * Refs to DIVs below contain stream videos and rendered conditionally.
   * Checks for them being present (created) are required.
   */
  @Ref() readonly video!: HTMLVideoElement

  private hasVideo = false
  private mediaInterval = 0

  get avatarSize (): string {
    if (this.size === 's') return '64px'
    if (this.size === 'm') return '88px'
    if (this.size === 'l') return '120px'
    throw new Error('Invalid size')
  }

  get name (): string {
    return this.isSelf ? this.$t('common.you').toString() : this.entity.name
  }

  get call (): ActiveCall {
    return activeCallStore.getters.activeCall
  }

  get stream (): MediaStream | null {
    if (this.isSelf) return this.call.localStreams.video ?? null

    return this.call.type === 'video_multistream'
      ? this.getMultistreamVideo()
      : this.getSinglestreamVideo()
  }

  getMultistreamVideo (): MediaStream | null {
    const index = activeCallStore.state.jids.indexOf(this.entity.jid)
    if (index === -1) return null
    return this.call.multistream.video[index] ?? null
  }

  getSinglestreamVideo (): MediaStream | null {
    const stream = this.call.remoteStreams.find(s => s.jid === this.entity.jid)
    return stream?.stream ?? null
  }

  get videoEnabled (): boolean {
    return this.entity.videoEnabled && this.hasVideo
  }

  get mirrored (): boolean {
    return this.isSelf && !this.call.isScreensharing
  }

  get isTalking (): boolean {
    const call = activeCallStore.state.activeCall
    if (!call) return false
    return call.talkingMembers.includes(this.entity.jid)
  }

  get isSelf (): boolean {
    return this.entity.jid === this.$store.getters.getUserId
  }

  created () {
    this.mediaInterval = setInterval(this.setMediaAvailability, 5000)
  }

  beforeDestroy (): void {
    clearInterval(this.mediaInterval)
  }

  @Watch('stream', { immediate: true })
  onStreamChanged (newS: CallMemberCard['stream']) {
    if (!newS) {
      this.hasVideo = false
      return
    }

    const tracks = newS.getVideoTracks()
    if (tracks.length < 1) {
      this.hasVideo = false
    } else {
      this.hasVideo = newS.getVideoTracks().some(t => {
        return t.enabled && !t.muted
      })
    }

    this.$nextTick(() => {
      if (!this.video || this.video.srcObject === newS) return
      this.video.srcObject = newS
    })
  }

  setMediaAvailability (): void {
    const tracks = this.stream?.getVideoTracks()
    if (!tracks || tracks.length === 0) {
      this.hasVideo = false
      return
    }

    tracks.forEach(t => {
      if (t.kind === 'video') this.hasVideo = t.enabled
    })
  }

  private openChat () {
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: this.entity.jid,
      },
    })
    window.goal('callControls', {
      callControls: 'Открыть чат карточка участника',
    })
  }
}
