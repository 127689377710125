
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'AddMembersHeader' })
export default class AddMembersHeader extends Vue {
  @Prop({
    type: Number,
    required: true,
  }) readonly numInviting!: number

  @Prop({
    type: Number,
    required: true,
  }) readonly numSpacesAvailable!: number

  get caption (): string {
    if (this.limitReached) return this.$t('calls.membersLimit').toString()

    return this.$t(
      'calls.addMembers.caption',
      { selected: this.numInviting, of: this.numSpacesAvailable },
    ).toString()
  }

  get limitReached (): boolean {
    return this.numInviting >= this.numSpacesAvailable
  }
}
