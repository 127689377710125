import { render, staticRenderFns } from "./ListItemsSection.vue?vue&type=template&id=fbc38f60&scoped=true&"
import script from "./ListItemsSection.vue?vue&type=script&lang=ts&"
export * from "./ListItemsSection.vue?vue&type=script&lang=ts&"
import style0 from "./ListItemsSection.vue?vue&type=style&index=0&id=fbc38f60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbc38f60",
  null
  
)

export default component.exports
import {QItem,QItemSection,QItemLabel,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QItemSection,QItemLabel,QIcon})
