
























import { Component, Vue, Prop } from 'vue-property-decorator'
import { matCancel } from '@quasar/extras/material-icons'

@Component({ name: 'AddMembersInput' })
export default class AddMembersInput extends Vue {
  @Prop({ type: String, required: true }) readonly value!: string
  @Prop({ type: String, default: '' }) readonly placeholder!: string
  @Prop({ type: Boolean, default: true }) readonly autofocus!: boolean
  @Prop({ type: Boolean, default: false }) readonly clearable!: boolean

  // icons signatures
  clearIcon!: string

  get computedClearable (): boolean {
    return this.clearable && this.value?.length > 0
  }

  created () {
    this.clearIcon = matCancel
  }
}
