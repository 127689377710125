










































import { activeCallStore, callsStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActiveCall } from '@/store/modules/activeCall/models'
import AddMembersBtn from './AddMembersBtn.vue'
import AddMembersContent from './AddMembersContent/index.vue'
import AddMembersHeader from './AddMembersHeader.vue'
import AddMembersInput from './AddMembersInput.vue'

@Component({
  components: {
    AddMembersBtn,
    AddMembersContent,
    AddMembersHeader,
    AddMembersInput,
  },
})
export default class AddMembers extends Vue {
  @Prop({ type: Object, required: true }) readonly call!: ActiveCall

  private searchQuery = ''

  private readonly selectedJids: string[] = []

  private get isAddBtnEnabled (): boolean {
    return this.selectedJids.length > 0
  }

  private get spacesLimit (): number {
    return callsStore.getters.membersLimit
  }

  private get spacesTaken (): number {
    return this.call.connectedMembers.length
  }

  private get spacesAvailable (): number {
    return this.spacesLimit - this.spacesTaken
  }

  private get numInviting (): number {
    return this.selectedJids.length
  }

  private onMemberClick (jid: string): void {
    const connectedJids = this.call.connectedMembers.map(m => m.jid)
    if (connectedJids.includes(jid)) return

    const i = this.selectedJids.indexOf(jid)
    i === -1
      ? this.selectedJids.push(jid)
      : this.selectedJids.splice(i, 1)
  }

  private cancel (): void {
    this.goBack()
  }

  private addMembers (): void {
    activeCallStore.actions.inviteMembers(this.selectedJids)
    window.goal('callControls', {
      callControls: 'Кнопка «Добавить» на экране добавления пользователей',
    })
    this.goBack()
  }

  private goBack (): void {
    activeCallStore.actions.toggleAddMembersDisplay()
  }
}
