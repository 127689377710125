

















































import {
  fetchDesktopCapturerSources,
  getStreamByDesktopCapturerSourceId,
} from '@/electron'
import { activeCallStore, callsStore, teamsStore } from '@/store'
import {
  ActiveCall,
  CallUiLgLayoutType,
} from '@/store/modules/activeCall/models'
import {
  IconGridM,
  IconListM,
  IconMessageSquareM,
  IconMicM,
  IconMicOffM,
  IconMoreHorizontalM,
  IconPresenterM,
  IconScreenshareM,
  IconUsersM,
  IconVideoM,
  IconVideoOffM,
  IconVolume2M,
  IconVolumeOffM,
} from '@tada/icons'
import { TdButtonCall } from 'td-ui'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ControlProps } from '../UniversalCallControl/types'

type ExtraControlType =
  | 'shown'
  | 'inMenu'

type ExtraControlsMap = { [N in ExtraControlType]: ControlProps[] }

const GUTTER = 16
const ITEM_WIDTH = 40
const PADDING_X = 16 * 2 // q-pa-sm

@Component({
  components: {
    IconMoreHorizontalM,
    TdButtonCall,
    TdDropdown: () => import('td-ui/dist/TdDropdown.vue'),
  },
})
export default class CallControlsStrip extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  @Prop({
    type: Number,
    default: 0,
  }) readonly width!: number

  /**
   * Number of buttons that interface has space reserved for.
   */
  get numBtnsSpaceReservedFor () {
    const itemSize = ITEM_WIDTH + GUTTER
    const availableWidth = this.width - PADDING_X - ITEM_WIDTH
    return Math.floor(availableWidth / itemSize) + 1
  }

  /**
   * Number of buttons that are always visible:
   * end call, open menu
   */
  numBtnsAlwaysVisible = 2

  get isVideoEnabled (): boolean {
    return window.FEATURES.calls_video_enabled
  }

  /**
   * Determines whether the button may be displayed.
   * This does not mean that user may actually add members to this call.
   */
  get canShowAddMembersBtn (): boolean {
    return activeCallStore.getters.addingMembersEnabled
  }

  get canRecordCall (): boolean {
    return window.FEATURES.calls_record
  }

  get shownControls (): ControlProps[] {
    return [this.muteAudioProps, ...this.extraControls.shown]
  }

  /**
   * Call controls split into those that are shown straight away,
   * and those that will be hidden inside ellipsis (...) menu.
   */
  get extraControls (): ExtraControlsMap {
    const shown: ControlProps[] = []
    const inMenu: ControlProps[] = []

    if (this.isVideoEnabled) {
      this.call.isScreensharing
        ? shown.push(this.screenshareProps)
        : shown.push(this.muteVideoProps)
    }

    shown.push(this.volumeProps)

    if (activeCallStore.state.uiDisplayType === 'card') return { shown, inMenu }

    this.canShowAddMembersBtn && shown.push(this.addMembersProps)

    if (this.isVideoEnabled && !this.call.isScreensharing) {
      shown.push(this.screenshareProps)
    }

    this.canChangeMemberLayout && shown.push(this.membersLayoutProps)
    this.canChangeLgLayout && shown.push(this.lgLayoutProps)
    shown.push(this.openChatProps)

    /**
     * Spliced (deleted) items will go inside menu.
     * Remaining (not deleted) will be visible straight away.
     * -1 to adjust for slice behaviour (N to M, both including)
     */
    if (shown.length > this.extraOptionsBtnsSpaceAvailable) {
      inMenu.push(...shown.splice(this.extraOptionsBtnsSpaceAvailable - 1))
    }

    return { shown, inMenu }
  }

  /**
   * Number of spaces for buttons left available on screen.
   * Extra options (controls) exceeding this number will be placed
   * inside ellipsis (...) menu.
   * This number includes ellipsis menu btn
   */
  get extraOptionsBtnsSpaceAvailable (): number {
    return this.numBtnsSpaceReservedFor - this.numBtnsAlwaysVisible
  }

  get canChangeLgLayout (): boolean {
    return activeCallStore.state.uiDisplayType === 'fullscreen'
  }

  get canChangeMemberLayout (): boolean {
    return (
      !activeCallStore.getters.isActiveCallDirect &&
      activeCallStore.state.uiDisplayType === 'bar'
    )
  }

  private endCall (): void {
    activeCallStore.actions.endCall()
  }

  get addMembersProps (): ControlProps {
    const taken = this.call.connectedMembers.length
    const total = callsStore.getters.membersLimit
    const caption = `${taken} / ${total}`
    const tooltip = taken >= total
      ? `${this.$t('calls.membersLimit')}: ${caption}`
      : caption

    const action = this.metrikaWrappedAction(
      activeCallStore.actions.toggleAddMembersDisplay,
      'Кнопка-иконка «Добавить участников»',
    )

    return {
      action,
      caption,
      icon: IconUsersM,
      label: this.$t('calls.inviteMembers').toString(),
      tooltip,
    }
  }

  get lgLayoutProps (): ControlProps {
    const target: CallUiLgLayoutType =
      activeCallStore.state.uiLgLayoutType === 'GRID' ? 'PRESENTER' : 'GRID'

    const action = this.metrikaWrappedAction(
      () => activeCallStore.actions.setUiLgLayoutType(target),
      `Изменить раскладку на ${target}`,
    )

    const label = target === 'GRID'
      ? this.$t('calls.lgLayout.toggleGrid').toString()
      : this.$t('calls.lgLayout.togglePresenter').toString()

    return {
      action,
      icon: target === 'GRID' ? IconGridM : IconPresenterM,
      label,
    }
  }

  get membersLayoutProps (): ControlProps {
    const currentIsCards = activeCallStore.state.membersDisplayType === 'CARDS'

    const action = this.metrikaWrappedAction(
      activeCallStore.actions.toggleMembersDisplayType,
      `Участники ${currentIsCards ? 'список' : 'карточки'}`,
    )

    const label = currentIsCards
      ? this.$t('calls.membersLayout.toggleList').toString()
      : this.$t('calls.membersLayout.toggleCards').toString()

    return {
      action,
      icon: currentIsCards ? IconListM : IconGridM,
      label,
      tooltip: label,
    }
  }

  get muteAudioProps (): ControlProps {
    const isMuted = this.call.isAudioMuted

    const action = this.metrikaWrappedAction(
      activeCallStore.actions.toggleAudio,
      `Мьют ${isMuted ? 'выключить' : 'включить'}`,
    )

    const label = !isMuted
      ? this.$t('calls.mute.off').toString()
      : activeCallStore.state.uiDisplayType === 'fullscreen'
        ? this.$t('calls.mute.onFullscreen').toString()
        : this.$t('calls.mute.on').toString()

    return {
      action,
      active: isMuted,
      // disable: !isMicAvailable,
      icon: isMuted ? IconMicOffM : IconMicM,
      label,
      tooltip: label,
    }
  }

  get muteVideoProps (): ControlProps {
    const isMuted = this.call.isVideoMuted

    const action = this.metrikaWrappedAction(
      activeCallStore.actions.toggleVideo,
      `Камера ${isMuted ? 'выключить' : 'включить'}`,
    )

    const label = isMuted
      ? this.$t('calls.video.on').toString()
      : this.$t('calls.video.off').toString()

    return {
      action,
      active: isMuted,
      icon: isMuted ? IconVideoOffM : IconVideoM,
      label,
      tooltip: label,
    }
  }

  get openChatProps (): ControlProps {
    const action = this.metrikaWrappedAction(
      () => {
        this.$router.push({
          name: 'Chat',
          params: {
            teamId: teamsStore.getters.currentTeam.uid,
            jid: this.call.jid,
          },
        })

        if (activeCallStore.state.uiDisplayType === 'fullscreen') {
          activeCallStore.actions.setUiDisplayType(
            activeCallStore.state.uiDisplayTypePrev,
          )
        }
      },
      'Написать в чат',
    )

    const label = this.$t('calls.openChat').toString()

    return {
      action,
      icon: IconMessageSquareM,
      label,
      tooltip: label,
    }
  }

  private get screenshareProps (): ControlProps {
    const active = this.call.isScreensharing

    const action = async (event?: Event) => {
      if (active) {
        this.goal('`Шаринг экрана выключить')
        await activeCallStore.actions.stopScreenshare()
        return
      }

      this.goal('`Шаринг экрана включить')

      if (window.isElectron) {
        if (event) {
          event.preventDefault()
          event.stopPropagation()
        }

        const sources = await fetchDesktopCapturerSources()
        const screenSources = sources.filter(source => source.id.indexOf('screen:') === 0)

        if (screenSources.length === 0) return

        const select = async (sourceId: string) => {
          const stream = await getStreamByDesktopCapturerSourceId(sourceId)
          await activeCallStore.actions.setScreenshareStream(stream)
        }

        // auto select for one screen
        if (screenSources.length === 1) {
          await select(screenSources[0].id)
          return
        }

        // TODO: control
        // if (!this.control) return

        // this.control.showMenu(screenSources.map(source => ({
        //   label: source.name,
        //   action: () => select(source.id),
        // })))

        return
      }

      await activeCallStore.actions.startScreenshareOnBrowser()
    }

    const label = active
      ? this.$t('calls.screensharing.off').toString()
      : this.$t('calls.screensharing.on').toString()

    return {
      action,
      icon: IconScreenshareM,
      label,
      tooltip: label,
    }
  }

  private get volumeProps (): ControlProps {
    const isVolumeOff = this.call.outputVolume === 0

    const action = this.metrikaWrappedAction(
      activeCallStore.actions.toggleOutputSound,
      `Звук ${isVolumeOff ? 'включить' : 'выключить'}`,
    )

    const label = isVolumeOff
      ? this.$t('calls.volume.on').toString()
      : this.$t('calls.volume.off').toString()

    return {
      action,
      active: isVolumeOff,
      icon: isVolumeOff ? IconVolumeOffM : IconVolume2M,
      label,
      tooltip: label,
    }
  }

  metrikaWrappedAction (action: () => void, desc: string): () => void {
    return () => {
      this.goal(desc)
      action()
    }
  }

  private goal (desc: string): void {
    const message = `${desc} ${activeCallStore.getters.uiDisplayTypeCode}`
    window.goal('callControls', { callControls: message })
  }
}
