





































import { activeCallStore, teamsStore } from '@/store'
import { ActiveCall } from '@/store/modules/activeCall/models'
import { Component, Prop, Vue } from 'vue-property-decorator'

import CallDisplayTypeBtns from '@/components/Calls/ActiveCall/CallDisplayTypeBtns.vue'

@Component({
  components: {
    CallDisplayTypeBtns,
    CallAddMembersBtn: () => import('./CallAddMembersBtn.vue'),
    IconDotCircle: () => import('@/components/UI/icons/IconDotCircle.vue'),
  },
})
export default class CallDetailsHeader extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  @Prop({
    type: Boolean,
    default: false,
  }) readonly isDragging!: boolean

  private get canShowAddMembersBtn (): boolean {
    return activeCallStore.getters.addingMembersEnabled
  }

  private get chatName (): string {
    return this.$store.getters.chatName(this.call.jid)
  }

  private get timer (): string | void {
    const state = this.call.lifecycleState
    if (state !== 'CONNECTED') return

    const time = activeCallStore.getters.activeCallFormattedLength
    if (time) return time
  }

  private get caption (): string {
    return activeCallStore.getters.localizedLifecycleState(this.call)
  }

  private get isChatOpen (): boolean {
    return this.$store.getters.currentChat === this.call.jid
  }

  get chatNameClass (): Record<string, boolean> {
    return {
      'call-details-header__name--hoverable': !this.isChatOpen,
      'cursor-pointer': !this.isChatOpen,
      'disable-click': this.isDragging,
    }
  }

  private openChat (): void {
    if (this.isChatOpen) return

    this.$router.push({
      name: 'Chat',
      params: {
        teamId: teamsStore.getters.currentTeam.uid,
        jid: this.call.jid,
      },
    })

    const displayType = activeCallStore.getters.uiDisplayTypeCode
    const text = `Открыть чат шапка в ${displayType}`
    window.goal('callControls', { callControls: text })
  }
}
